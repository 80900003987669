import styles from './Form.module.scss';

interface IComponentProperties {
    children: React.ReactNode[] | React.ReactNode,
    className?: string | undefined,
    formId?: string | undefined,
    hiddenSubmit?: boolean | undefined,
    onSubmit: Function,
    slotName?: string | undefined,
    method?: 'dialog' | undefined
};

export function submitForm (formId: string){
   
    const form:HTMLFormElement = document.getElementById(formId) as HTMLFormElement;
    if (form){
        if(form.checkValidity() || form.reportValidity() )
        {
            console.log(form,form.checkValidity())
            const submitButton = form.querySelector('button[type="submit"]') as HTMLButtonElement;
            
            if (submitButton){
                
                submitButton.click();
                
            }
        }

    }
};


export function Form(props: IComponentProperties){
    
    function handleSubmit(event: React.FormEvent<HTMLFormElement>){

        // Prevent the browser from reloading the page
        event.preventDefault();

        const formElement: HTMLFormElement = event.target as HTMLFormElement;
        
        // Is Valid Form
        if (formElement.checkValidity()|| formElement.reportValidity()){

            // Read the form data
            const formData = new FormData(formElement);
            const formJson = Object.fromEntries(formData.entries());

            // Send dispatch on submit event
            props.onSubmit(formJson);
        }

    }

    function renderHiddenSubmitButton (){
        
        if (props.hiddenSubmit === true){
            return <button className={styles['Form-hiddenSubmitButton']} type="submit"></button>;
        }

        return <></>;
    }

    return (
        <form 
            id={props.formId}
            slot={props.slotName} 
            className={`${styles['Form']} ${props.className || ''}`} 
            onSubmit={handleSubmit} 
            method={props.method}
            noValidate>
            {props.children}
            {renderHiddenSubmitButton()}
        </form>
    );
}