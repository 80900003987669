import { useLayoutEffect, useState } from "react";

import { useActionChecker, usePageStrings } from "../../../common/hooks";
import { useLayout } from "../../../common/hooks/useLayout";

import { Action, ActionStatus, Result, useAppDispatch } from "../../../store";
import { hideProgress, showProgress } from "../../../store/slices/layout";
import { appLogService } from "../../../store/slices/appLog";
import { settingsService } from "../../../store/slices/settings";

import { ListDetailLayout } from "../../../common/layouts/canonicalLayouts";
import { SettingsListPane } from './settingsListPane/SettingsListPane';
import { SettingsDetailPane } from './settingsDetailPane/SettingsDetailPane';
import { SettingsEmptyPane } from "./settingsEmptyPane/SettingsEmptyPane";
import { Settings as SettingsViewModel } from "../../../viewModels/configuration/settings";
import { Authentication } from "../../../viewModels/configuration/settings/authentication";
import { ApprovalRequestEmail } from "../../../viewModels/configuration/approvalRequestEmail";
import { VisibilityOption } from "../../../viewModels/configuration/visibilityOption";
import { AccountSelfCreation } from "../../../viewModels/configuration/accountSelfCreation";
import { addSnackbarMessage, SnackbarMessage } from "../../../store/slices/snackbar";
import { BannerMessage } from "../../../common/components";

import styles from "./Settings.module.scss"

export function Settings () {
    
    const dispatch = useAppDispatch();
    const actionChecker = useActionChecker();
    const pageStrings = usePageStrings();

    const [isDetailPaneOpened, setIsDetailPaneOpened] = useState(false);
    const [selectedSettingItemId, setSelectedSettingItemId] = useState('');
    const [settings, setSettings] = useState<SettingsViewModel>(getDefaultSettings());    
    const [settingsSavedStatus, setSettingsSavedStatus] = useState(Action.idle());

    useLayout({
        pageTitle: pageStrings.settingsPage.pageTitle,
        canAccessPolicy: 'settings.canAccess.policy'
    });


    useLayoutEffect(() => {

        dispatch(showProgress());

        settingsService.getSettings().then((response: SettingsViewModel) => {
            console.log(response);
            setSettings(response);
        }).catch((ex) => {
            appLogService.error('Settings.tsx', 'useLayoutEffect', 'getSettings', ex.message);
        }).finally(() => {
            dispatch(hideProgress());
        });

    }, [dispatch]);

    function handleOpenDetailPane (itemId: string) {
        setIsDetailPaneOpened(true);
        setSelectedSettingItemId(itemId);
    }

    function handleCloseDetailPane () {
        setIsDetailPaneOpened(false);
        setSelectedSettingItemId('');
    }

    function handleAuthenticationFormSubmitted (formData: Authentication){

        if (!actionChecker.canDoAction("settings.canEdit.policy")){
            return;
        }

        settingsService.saveAuthenticationConfiguration({
          adfs_clientId: formData.adfs_clientId,
          adfs_isEnabled: formData.adfs_isEnabled,
          adfs_loginPropertyName: formData.adfs_loginPropertyName,
          adfs_orgUri: formData.adfs_orgUri,
          azure_clientId: formData.azure_clientId,
          azure_clientSecret: formData.azure_clientSecret,
        //   azure_clientSecretExpiryDate: formData.azure_clientSecretExpiryDate,
          azure_isEnabled: formData.azure_isEnabled,
          azure_loginNameProperty: formData.azure_loginNameProperty,
          azure_tenantName: formData.azure_tenantName,
          okta_accountName: formData.okta_accountName,
          okta_appId: formData.okta_appId,
          okta_appSecret: formData.okta_appSecret,
          //okta_appSecretExpiryDate: formData.okta_appSecretExpiryDate,
          okta_authorizationServer: formData.okta_authorizationServer,
          okta_isEnabled: formData.okta_isEnabled,
          okta_loginNameProperty: formData.okta_loginNameProperty,
          usernameAndPassword_isEnabled: formData.usernameAndPassword_isEnabled,  
        }).then((result: Result<boolean>) => {

            console.log(result,settings);
            if (result.isSuccess){
                let savedSettings: SettingsViewModel = {
                    accountSelfCreation:settings.accountSelfCreation,
                     authentication: {
                        adfs_clientId: formData.adfs_clientId,
                        adfs_isEnabled: formData.adfs_isEnabled,
                        adfs_loginPropertyName: formData.adfs_loginPropertyName,
                        adfs_orgUri: formData.adfs_orgUri,
                        azure_clientId: formData.azure_clientId,
                        azure_clientSecret: formData.azure_clientSecret,
                      //   azure_clientSecretExpiryDate: formData.azure_clientSecretExpiryDate,
                        azure_isEnabled: formData.azure_isEnabled,
                        azure_loginNameProperty: formData.azure_loginNameProperty,
                        azure_tenantName: formData.azure_tenantName,
                        okta_accountName: formData.okta_accountName,
                        okta_appId: formData.okta_appId,
                        okta_appSecret: formData.okta_appSecret,
                        //okta_appSecretExpiryDate: formData.okta_appSecretExpiryDate,
                        okta_authorizationServer: formData.okta_authorizationServer,
                        okta_isEnabled: formData.okta_isEnabled,
                        okta_loginNameProperty: formData.okta_loginNameProperty,
                        usernameAndPassword_isEnabled: formData.usernameAndPassword_isEnabled,  
                     },
                 };
    
                setSettings(savedSettings);
                setSettingsSavedStatus(Action.succeeded);
                let sb =  new SnackbarMessage('Save Sucessful',5) 
                dispatch(addSnackbarMessage(sb));
            
            } else {
                setSettingsSavedStatus(Action.failed(result.errorMessage));
                appLogService.error('Settings.tsx', 'handleSaveAuthenticationActivated', 'ResultError', result.errorMessage);
            }

        })
        .catch((ex) => {
            appLogService.error('Settings.tsx', 'handleSaveAuthenticationActivated', 'CallbackError', ex.message);
        })
        .finally();

    }
    function handleAccountSelfCreationFormSubmitted (formData: AccountSelfCreation){
        if (!actionChecker.canDoAction("settings.canEdit.policy")){
            return;
        }
        console.log(formData);
         settingsService.saveAccountSelfCreationConfiguration(formData)
            .then((value) => {
                console.log(value,settings);
                if(value.isSuccess)
                {
                    let savedSettings: SettingsViewModel = {
                    accountSelfCreation:{
                            approvalRequired_IsEnabled : formData.approvalRequired_IsEnabled,
                            approvalRequired_SentTo : formData.approvalRequired_SentTo,
                            approvalRequired_SpecificEmailAddress : formData.approvalRequired_SpecificEmailAddress,
                            formConfiguration_Department : formData.formConfiguration_Department,
                            formConfiguration_Location : formData.formConfiguration_Location,
                            formConfiguration_PracticeArea : formData.formConfiguration_PracticeArea,
                            formConfiguration_Telephone : formData.formConfiguration_Telephone,
                            formConfiguration_Title : formData.formConfiguration_Title,
                            formConfiguration_Username : formData.formConfiguration_Username,
                            formConfiguration_UserProfile: formData.formConfiguration_UserProfile,
                            isEnabled: formData.isEnabled
                        },
                        authentication: settings.authentication,
                    };
        
                    setSettings(savedSettings);
                    setSettingsSavedStatus(Action.succeeded);
                    let sb =  new SnackbarMessage('Save Sucessful',5) 
                    dispatch(addSnackbarMessage(sb));
                }
                else
                {
                    setSettingsSavedStatus(Action.failed(value.errorMessage));
                appLogService.error('Settings.tsx', 'handleAccountSelfCreationFormSubmitted', 'ResultError', value.errorMessage); 
                }
            })
            .catch((ex) => {
                appLogService.error('Settings.tsx', 'handleAccountSelfCreationFormSubmitted', 'CallbackError', ex.message);
            })
            .finally();

    }

    function getDefaultSettings(): SettingsViewModel{
        return{
            accountSelfCreation:{
                isEnabled: false,
                approvalRequired_IsEnabled: false,
                approvalRequired_SentTo: ApprovalRequestEmail.DontSend,
                approvalRequired_SpecificEmailAddress: '',
                formConfiguration_Username: VisibilityOption.Hidden,
                formConfiguration_Telephone: VisibilityOption.Hidden,
                formConfiguration_Location: VisibilityOption.Hidden,
                formConfiguration_Title: VisibilityOption.Hidden,
                formConfiguration_Department: VisibilityOption.Hidden,
                formConfiguration_PracticeArea: VisibilityOption.Hidden,
                formConfiguration_UserProfile: VisibilityOption.Hidden

            },
            authentication:{
                adfs_clientId: '',
                adfs_isEnabled: false,
                adfs_loginPropertyName: '',
                adfs_orgUri: '',
                azure_clientId: '',
                azure_clientSecret: '',
                //azure_clientSecretExpiryDate: Date;
                azure_isEnabled: false,
                azure_loginNameProperty: '',
                azure_tenantName: '',
                okta_accountName: '',
                okta_appId: '',
                okta_appSecret: '',
                //okta_appSecretExpiryDate: Date;
                okta_authorizationServer: '',
                okta_isEnabled: false,
                okta_loginNameProperty: '',
                usernameAndPassword_isEnabled: false
            }
        } ;
    }

    return (
        <>
             {settingsSavedStatus.message &&settingsSavedStatus.status === ActionStatus.failed && (     
                <div className={styles["Settings-saved-errorMessage"]}>
                    <BannerMessage messageType="error" message={settingsSavedStatus.message }></BannerMessage>
                </div>
            )} 
            <ListDetailLayout isDetailPaneOpened={isDetailPaneOpened}
                listPane={
                    <SettingsListPane itemSelected={handleOpenDetailPane} selectedId={selectedSettingItemId}></SettingsListPane>
                }
                detailPane={
                    <SettingsDetailPane 
                        detailPaneClosed={handleCloseDetailPane} 
                        selectedId={selectedSettingItemId} 
                        settings={settings}
                        authenticationFormSubmitted={handleAuthenticationFormSubmitted}
                        accountSelfCreationFormSubmitted={handleAccountSelfCreationFormSubmitted}
                        >
                    </SettingsDetailPane>
                } emptyDetailPane={
                    <SettingsEmptyPane>
                        
                    </SettingsEmptyPane>
            }></ListDetailLayout>
            
        </>
    );
}
